module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Strategiemoderator - Christoph Hauke","short_name":"Christoph Hauke","start_url":"/","background_color":"#fff","theme_color":"#B2CA38","display":"standalone","icon":"./static/images/favicon.png","icons":[{"src":"favicon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicon/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7096f607490b44a373c9559b5113fb2f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://2a06c65bc33243ad8a6969320913b6a0@sentry.io/1777530","environment":"production","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
